.appPartnerNavbarRoot {
  background-color: #FFF;
  position: relative;
  z-index: 31;
}
.appPartnerNavbarWrapper {
  height: 50px;
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  max-width: 1170px;
  margin: 0 auto;

  .logotype {
    height: 50px;
    width: auto;
  }

  @include bp-small {
    justify-content: center;
  }
}

.logo-for-partner {
  margin-top: 50px;
  max-width: 160px;

  @include bp-small {
    margin-top: 0;
  }

  &.header{
    margin-top: 0;
    height: max-content;
  }
}
