body {
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #F2F2F2;
}

h1, h2, h3, h4, h5, h6, p, a, ol li {
  margin: 0;
  font-family: $font-primary-regular;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
  // new line after backslash n
  white-space: pre-line;
}

h5 {
  font-size: 16px;
}

p {
  font-size: 16px;
}

.formInputHidden{
  display: none;
}

input{
  filter: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus{
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: inset 0 0 0 9999px white;
}

.icon-loader-primary {
  stroke: $color-background-header;
}
