ol.progtrckr {
    list-style-type: none;
    display: grid;
    padding: 0;
    grid-auto-flow: column;
    grid-template-columns: repeat(4, 1fr);
    margin-top: -25px;
}

ol.progtrckr a{
    position: relative;
    text-decoration: none;
    color: #212121;
}

@media only screen and (max-width: 767px) {
  ol.progtrckr a{
    display: flex;
    align-items: center;
  }
}

ol.progtrckr li {
    display: flex;
    text-align: center;
    cursor: pointer;
    flex-direction: column;
}
ol.progtrckr li span {
    padding: 0;
}


.progtrckr em {
    display: none;
    font-weight: 700;
    padding-left: 1rem;
}
@media (max-width: 650px) {
    .progtrckr em {
        display: inline;
    }
}

ol.progtrckr li.progtrckr-todo {
    color: silver;
    position: relative;
}

ol.progtrckr li.progtrckr-todo div{
    display: flex;
    flex-direction: column;
}

.sub-navigation-bottom-line {
    content: "";
    position: absolute;
    border-top: 2px solid #C8C8C8;
    width: 100%;
    top: 103px;
    left: 50%;
    right: -50%;
    z-index: 2;
}

ol.progtrckr a.progtrckr-todo:not(:last-child):after {
    @extend .sub-navigation-bottom-line;
}
ol.progtrckr li.progtrckr-doing {
    color: #A7A7A7;
    position: relative;
}
ol.progtrckr li.progtrckr-doing div{
    display: flex;
    flex-direction: column;
}

ol.progtrckr a.progtrckr-doing:not(:last-child):after {
    @extend .sub-navigation-bottom-line;
}
ol.progtrckr li.progtrckr-done {
    color: #A7A7A7;
    position: relative;
}
ol.progtrckr li.progtrckr-done div {
    display: flex;
    flex-direction: column;
}

ol.progtrckr li.progtrckr-done:after {
    @extend .sub-navigation-bottom-line;
    border-top: 2px solid var(--secondary-color);
}
ol.progtrckr li:before {
    position: relative;
    top: 93px;
    float: left;
    left: 43%;
    z-index: 3;
}
ol.progtrckr li.progtrckr-todo:before {
    content: "";
    color: silver;
    background-color: white;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    border: 1px solid #C8C8C8;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
}
ol.progtrckr li.progtrckr-todo:hover:before {
    color: #F9733E;
}
ol.progtrckr li.progtrckr-doing:before {
    content: "\2022";
    font-size: 12px;
    color: var(--secondary-color);
    background-color: #ffffff;
    width: 20px;
    line-height: 20px;
    border-radius: 50px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
ol.progtrckr li.progtrckr-doing:hover:before {
    color: var(--secondary-color);
}

ol.progtrckr li.progtrckr-done:before {
    content: url("../../resources/images/tick.svg");
    background-color: var(--secondary-color);
    width: 20px;
    line-height: 16px;
    border-radius: 50px;
    height: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
ol.progtrckr li.progtrckr-done:hover:before {
    color: #0FA0CE;
}

.multiStepContentWrapper {
    display: grid;
    grid-template-columns: 1.2fr 4fr auto;
    align-items: center;
    width: 100%;
    margin: 0 auto;
}

.btns {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 0.5em;

    @media only screen and (min-width : 768px) {
        min-width: 153px;
        button:first-child {
            margin-left: auto;
        }
    }

    button {
        position: relative;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 50px;
        height: 45px;
        font-size: 16px;
        font-family: $font-primary-bold;
        &:hover {
            opacity: 0.7;
        }

        svg {
            width: 60%;
            height: 60%;
            position: absolute;
            top: 20%;
            left: 20%;
        }
    }

    .prev-btn, .next-btn, .last-prev-btn {
        width: 45px;
    }

    .finish-btn {
        width: 90px;
        border-radius: 30px;
        color: #ffffff;
        background-color: var(--secondary-color);
        font-size: 16px;
        font-family: $font-primary-bold;
    }

    .clearFormBtn {
        width: 100px;
        border: 1px solid var(--secondary-color);
        border-radius: 23px;
        color: var(--secondary-color);
        background-color: $white;
    }

    .prev-btn {
        background-color: #ffffff;
        border: 1px solid var(--secondary-color);
    }

    .last-prev-btn {
        background-color: #ffffff;
        border: 1px solid var(--secondary-color);
    }

    .next-btn {
        background-color: var(--secondary-color);
    }
}

.multiStepTitle {
   font-size: 14px;
   color: #2A363D;
   font-family: $font-primary-bold;
}

.multiStepIcon {
    margin-bottom: 7px;
    height: 28px;
}


@media only screen and (max-width: 767px) {
    .multiStepContentWrapper {
        grid-template-columns: 3fr auto;
    }
    ol.progtrckr li.progtrckr-doing:before {
        display: none;
    }
    ol.progtrckr li.progtrckr-todo:before {
        display: none;
    }
    ol.progtrckr li.progtrckr-todo {
        display: none;
    }
    ol.progtrckr li.progtrckr-done {
        display: none;
    }
    ol.progtrckr li.progtrckr-doing:not(:last-child):after {
        display: none;
    }
    .btns {
        justify-self: center;

        .next-btn {
            svg {
                left: 25%;
                top: 25%;
                width: 50%;
                height: 50%;
            }
        }
        .prev-btn{
            svg {
                left: 25%;
                top: 25%;
                width: 50%;
                height: 50%;
            }
        }

        .prev-btn, .next-btn, .last-prev-btn {
            width: 30px;
            height: 30px;
        }
        .finish-btn {
            width: 70px;
            height: 30px;
            font-size: 14px;
        }
        .clearFormBtn {
            height: 30px;
            width: 100px;
            font-size: 14px;
        }
    }
    .multiStepIcon {
        margin-bottom: 0;
        height: auto;
        width: 30px;
    }
    .multiStepTitle {
        font-size: 20px;
        order: -1;
        text-align: left;
    }
    ol.progtrckr {
        margin-top: 0;
        grid-template-columns: 1fr;
        margin-bottom: 0;
    }
    ol.progtrckr li {
        flex-direction: row;
    }
    ol.progtrckr li.progtrckr-doing div {
        align-items: flex-start;
        padding-left: 15px;
    }
    ol.progtrckr li span {
        padding: 0;
    }
}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {
    .postalCodeData {
        padding-left: 30px;
    }
    .multiStepContentWrapper {
        grid-template-columns: 1fr 3fr auto;
    }
    ol.progtrckr li:before {
        // top: 108px;
    }
    ol.progtrckr li.progtrckr-done:after {
        left: 86px;
        right: -74px;
    }
    ol.progtrckr li.progtrckr-doing:not(:last-child):after {
        left: 86px;
        right: -66px;
    }
    ol.progtrckr li.progtrckr-todo:not(:last-child):after {
        left: 86px;
        right: -66px;
    }
}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {
    .postalCodeData {
        padding-left: 30px;
    }
    ol.progtrckr li span {
        padding: 0 1rem;
    }
    .multiStepContentWrapper {
        grid-template-columns: 1fr 3fr auto;
    }
    ol.progtrckr li:before {
        // top: 108px;
    }
    ol.progtrckr li.progtrckr-done:after {
        left: 69px;
        right: -54px;
    }
    ol.progtrckr li.progtrckr-doing:not(:last-child):after {
        left: 69px;
        right: -55px;
    }
    ol.progtrckr li.progtrckr-todo:not(:last-child):after {
        left: 69px;
        right: -55px;
    }
}
