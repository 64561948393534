h1 {
  color: var(--primary-color);
}

.addVehiclesFormNavBar {
  background-color: $white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  position: fixed;
  width: 100%;
  top: 50px;
  z-index: 30;
  @include bp-small {
    top: 0;
    position: relative;
  }
}

.addVehiclesFormNavBarPartners {
  background-color: $white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  width: 100%;
  position: relative;
  z-index: 30;
  @include bp-small {
    top: 0;
    position: relative;
  }
}

@include bp-small {
  .postalCodeData {
    display: none;
  }
}

@include bp-small {
  .add-vehicles-content-wrapper {
    padding-top: 0;
  }
}

.addVehiclesFormNavBarContent {
  width: calc(100% - 60px);
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 150px;

  @include bp-small {
    height: 85px;
  }

  h2 {
    color: $myChoiceDarkBlue;
    font-family: $font-primary-black;
  }

  p {
    color: $myChoiceGray;
  }
}

.postalCodeDataMobile {
  display: none;
  @include bp-small {
    background-color: var(--secondary-color);
    height: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 2;
    h2 {
      color: $white;
    }
    p {
      color: $myChoiceBlack;
      margin-top: 4px;
    }
  }
}

.addVehiclesFormContentWrapper {
  background-color: $myChoiceContentBackground;
  display: flex;
  padding: 115px 0 75px 0;
  align-items: center;
  flex-direction: column;

  .logo-for-partner {
    align-self: center;
  }

  @include bp-small {
    align-items: stretch;
    background-color: $white;
    padding: 0 0 75px 0;
    br {
      display: none;
    }
  }

  .loading {
    display: flex;
    flex-direction: row;
    padding-left: 44%;

    div {
      height: 7px;
      width: 7px;
      margin-right: 3%;
      background-color: $white;
      border-radius: 50%;
      animation-name: blink;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;

      &:nth-child(2) {
        animation-delay: .1s;
      }

      &:nth-child(3) {
        animation-delay: .2s;
      }
    }

    @keyframes blink {
      0% {
        opacity: .2;
      }
      20% {
        opacity: 1;
      }
      100% {
        opacity: .2;
      }
    }
  }

  .warningMessage {
    font-size: 14px;
    color: red;
    margin-bottom: 15px;
  }

  .controlAndTabsWrapper {
    position: relative;
    min-height: 320px;
  }


  .tabNavigationWrapper {
    width: 465px;
    max-width: 465px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 7px;
    overflow: hidden;
    position: relative;

    .tabLeftArrow {
      width: 35px;
      height: 70px;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 7px 0 0 0;
      position: absolute;
      left: 0;
      cursor: pointer;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 10px;
      }
    }

    .tabRightArrow {
      width: 35px;
      height: 70px;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 0 7px 0 0;
      position: absolute;
      right: 0;
      cursor: pointer;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 10px;
      }
    }

    .singleTab {
      height: 70px;
      width: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 7px 7px 0 0;
      cursor: pointer;
      box-shadow: 2px 0 5px rgba(0, 0, 0, 0.16);
      position: relative;

      h4 {
        color: $white;
      }
    }

    .activeTab {
      background-color: var(--primary-color);

      h4 {
        font-family: $font-primary-bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 10ch;
      }
    }

    .newTab {
      background-color: $myChoiceTabBackground;

      h4 {
        color: $myChoiceBlack;
      }
    }

    .inactiveTab {
      // background-color: $myChoiceInactiveOrange;
      background-color: var(--primary-hover);

      h4 {
        font-family: $font-primary-bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 10ch;
      }
    }

    .deleteBtn {
      position: absolute;
      top: 7px;
      right: 8px;
      width: 14px;
      height: 14px;
    }
  }

  .tabNavigationWrapperDesktopMargin {
    width: 930px;
    max-width: 930px;
    grid-template-columns: repeat(6, 1fr);
    -webkit-transition: margin-left 0.5s ease-in-out;
    -moz-transition: margin-left 0.5s ease-in-out;
    -o-transition: margin-left 0.5s ease-in-out;
    transition: margin-left 0.5s ease-in-out;
  }

  .formWrapper {
    background-color: $white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 0 0 4px 4px;
    width: 465px;
    max-width: 465px;
    padding: 66px 30px 30px 30px;
    position: relative;
    box-sizing: border-box;
    @include bp-small {
      width: auto;
      box-shadow: none;
      border: none;
      border-radius: 0;
      margin: 0 auto;
    }
  }

  .signUpWrapper {
    border-radius: 4px;
    padding-top: 30px;

    h3 {
      text-align: center;
      font-family: $font-primary-light;
    }
  }

  .discountSecondStep {

    background-color: $white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    width: 465px;
    max-width: 465px;
    position: relative;
    box-sizing: border-box;
    margin: 0 auto;

    @include bp-small {
      width: auto;
      box-shadow: none;
      border: none;
      border-radius: 0;
    }

    .stepIntroTitle {
      p {

        font-size: 18px;
        span {
          color: var(--primary-color);
        }
      }
    }
    .stepCenterTitle {
      @extend .stepIntroTitle;
      text-align: center;
    }

    .fonts-regular {
      font-family: $font-primary-regular;
    }

    .stepMiddleDescription {
      @extend .stepIntroTitle;

      p {
        font-size: 16px;
        line-height: 18px;

        a {
          color: $color-blue-light;
          margin-left: 0.25em;
          text-decoration: underline;
        }
      }
    }

    .stepEndDescription {
      @extend .stepIntroTitle;

      p {
        font-size: 12px;
        line-height: 14px;
        a{
          font-weight: 700;
          text-decoration: underline;
          color: $color-blue-light;
        }
      }
    }
  }

  .discountSecondStepTopRow {
    h5 {
      padding: 0 0 12px 0;
      margin-top: 10px;
      margin-bottom: 10px;
      color: $myChoicePlaceholderGray;
      font-family: $font-primary-regular;
      text-align: center;
    }

    .quotesPriceBar {
      background: var(--primary-color);

      h5 {
        font-family: $font-primary-black;
        font-size: 22px;
        padding: 15px 0;
        color: $white;
        @include bp-small {
          font-size: 18px;
        }
      }
    }
  }

  .stepIntroTitle {
    margin-bottom: 30px;
    line-height: 24px;
    font-family: $font-primary-bold;
  }

  .discountSecondStepBottomRow {
    padding: 20px 30px 30px 30px;

    h3 {
      font-family: $font-primary-bold;
      padding-bottom: 60px;
      @include bp-small {
        padding-bottom: 40px;
      }
    }

    .stepIntroBoldTitle {
      @extend .stepIntroTitle;
      font-family: $font-primary-black;
      text-align: center;
      font-size: 22px;
      padding: 15px 0;
      @include bp-small {
        font-size: 18px;
      }
    }

    .checkbox {
      margin: 30px auto 60px auto;
    }

    .checkbox label span {
      position: absolute;
      top: 115%;
      transform: translateY(-25%);
      font-family: $font-primary-regular;
      @include bp-small {
        transform: translateY(-18%);
      }

      b {
        color: var(--secondary-color);
        font-family: $font-primary-bold;
      }
    }

    .checkbox label {
      margin-bottom: 80px;
      @include bp-small {
        margin-bottom: 120px;
      }
    }

    .selectedBtn, .deSelectedBtn {
      &:hover {
        opacity: 0.7;
      }
    }

    .priceDropAlertFields {
      margin-top: 20px;
    }

    .alreadyMemberHintText {
      text-align: center;

      span {
        font-family: $font-primary-bold;
        padding-left: 10px;
        color: var(--secondary-color);
        cursor: pointer;
      }
    }

    .centerTitle {
      text-align: center;
      h2 {
        font-family: $font-primary-black;
        font-size: 1.4em;
        padding: 10px 0 30px 0;
      }
    }
  }

  .recommendedRangeInfo {
    color: $myChoiceGray;
    padding-right: 30px;
    margin-top: -15px;
    margin-bottom: 15px;
  }

  .licensedHint {
    margin-bottom: 30px;
  }

  .licensedHintForDate {
    margin-top: 10px;
  }

  .bottomFormWrapper {
    background-color: $white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    width: 465px;
    max-width: 465px;
    margin-top: 30px;
    padding: 50px 30px 45px 30px;
    box-sizing: border-box;
    @include bp-small {
      width: 100%;
      margin: 0 auto;
      box-shadow: none;
      border: none;
      border-radius: 0;
      padding: 0 30px 45px 30px;
    }

    h2 {
      margin-bottom: 47px;
      font-family: $font-primary-bold;
    }
  }

  .bottom-navigation-btns-wrapper {
    display: flex;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    max-width: 465px;
    @include bp-small {
      margin: 0 auto;
      padding: 0 30px 45px 30px;
    }

    .bottom-previous-btn {
      width: 160px;
      height: 45px;
      font-size: 18px;
      font-family: $font-primary-bold;
      margin-right: 5px;
      background-color: transparent;
      border: 1px solid var(--secondary-color);
      border-radius: 4px;
      color: var(--secondary-color);
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }

    .bottom-next-btn {
      width: 160px;
      height: 45px;
      font-size: 18px;
      font-family: $font-primary-bold;
      margin-left: 5px;
      background-color: var(--secondary-color);
      border: 1px solid var(--secondary-color);
      color: $white;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }

    .bottom-add-driver-btn {
      @extend .bottom-next-btn;
      background-color: $myChoiceSemiBlack;
      border: $myChoiceBlack;
    }
  }

  .additionalFieldsWrapper {
    border-top: 1px solid var(--primary-color);
    padding-top: 30px;

    .inputDate {
      margin-bottom: 30px;
    }

    .inputDateSelects {
      margin-bottom: 30px;
    }

    .removeAdditionalFields {
      color: var(--primary-color);
      border: 1px solid var(--primary-color);
      outline: none;
      border-radius: 50px;
      background: $white;
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .formLabelAndTooltipWrapper, .formButtonsWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    img {
      cursor: pointer;
    }

    h4 {
      font-family: $font-primary-bold;
      @include bp-small {
        font-size: 16px;
        padding-right: 15px;
      }
    }
  }

  .formButtonsWrapper {
    .selectedFullWidthBtn {
      width: 100%;
    }
  }


  .tooltipText {
    font-size: 14px;
    background: $white !important;
    color: $myChoiceSemiBlack;
    font-family: $font-primary-light;
    white-space: pre-line;
  }

  .tooltipWrapper {
    background-color: $white !important;
    opacity: 1 !important;
    width: 20%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    padding: 1em;
    @include bp-small {
      width: 70%;
      left: 35px !important;
    }
  }

  .tooltipIcon {
    background: url('../../resources/images/qm-not-filled.svg') no-repeat $white;
    height: 18px;
    width: 18px;
    flex-shrink: 0;

    &:hover {
      background: url('../../resources/images/qm-filled.svg') no-repeat $white;
      cursor: pointer;
    }
  }


  .underSelectCheckbox {
    margin-top: -15px !important;
  }

  .nextToCalendarInput {
    margin-top: 30px;
  }

  .formInput {
    @include input;

    &:hover {
      border: 1px solid var(--secondary-color);
    }
  }

  .phone-number-wrapper {

    .selected-flag {
      padding: 0 0 0 11px;
    }
    .flag-dropdown {
      background-color: transparent;
      cursor: auto;
    }
    .phone-input-component {
      padding-left: 50px;
    }
  }

  .email-input-wrapper {

    position: relative;
    margin: 15px 0 30px;

    .form-input {
      @include input;
      &:hover{
        border: $border-input-default;
      }
      margin: 15px 0 10px;
      padding-right: 50px;
    }

    .over-layer {
      position: absolute;
      right: 7px;
      top: 7px;
      width: 46px;
      height: 46px;
      background-color: transparent;
      border-radius: 50%;
      overflow: hidden;

      .icon-svg {
        width: 20px;
        height: 20px;
        margin-left: 14px;
        margin-top: 14px;
      }

      .icon-validate-loader {
        stroke: $color-text-tertiary;
      }
      .icon-validate-decline {
        width: 16px;
        height: 16px;
        margin-left: 16px;
        margin-top: 16px;
      }
    }

    .errorMsg {
      margin-bottom: 10px;
    }
  }

  .formInputActive {
    @include input;
    border: 1px solid var(--secondary-color);
  }

  .formSelect {
    @include input;
    @include select;

    &:hover {
      border: 1px solid var(--secondary-color);
    }
  }

  .formSelectActive {
    @include input;
    @include select;
    border: 1px solid var(--secondary-color);
  }

  .lessMarginSelect {
    margin-bottom: 15px;
  }

  .selectedBtn {
    @include buttonSelected
  }

  .deSelectedBtn {
    @include buttonDeSelected
  }

  .genderBtn {
    width: 31%;
    border: 1px solid $myChoicePlaceholderGray;
  }

  .addAnotherField {
    @include buttonSelected;
    width: 100%;
    height: 45px;
    font-weight: bold;
    margin: 10px 0;

    &:hover {
      opacity: 0.7;
    }
  }

  .afterDatePicker {
    margin-bottom: 30px;
  }

  .sectionTitle {
    padding-bottom: 15px;
    font-family: $font-primary-bold;
    font-size: 22px;
  }

  .checkbox {
    width: 100%;
    margin: 10px auto 30px auto;
    position: relative;
    display: block;

    label {
      position: relative;
      min-height: 21px;
      display: block;
      padding-left: 40px;
      margin-bottom: 0;
      font-weight: normal;
      cursor: pointer;

      span {
        position: absolute;
        top: 60%;
        transform: translateY(-40%);
        font-family: $font-primary-regular;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        margin: 4px;
        width: 18px;
        height: 18px;
        transition: transform 0.28s ease;
        border-radius: 3px;
        border: 1px solid $myChoicePlaceholderGray;
      }

      &:after {
        content: '';
        display: block;
        width: 10px;
        height: 5px;
        border-bottom: 1px solid var(--primary-color);
        border-left: 1px solid var(--primary-color);
        transform: rotate(-45deg) scale(0);
        transition: transform ease 0.25s;
        position: absolute;
        top: 9px;
        left: 9px;
      }
    }

    input[type="checkbox"] {
      width: 20px;
      opacity: 0.00000001;
      position: absolute;
      left: 2px;
      margin-left: 6px;
      top: 4px;
      z-index: 7;
      height: 20px;
      cursor: pointer;

      &:checked ~ label {
        &:before {
          border: 1px solid var(--primary-color);
        }

        &:after {
          transform: rotate(-45deg) scale(1);
        }
      }

      &:focus + label::before {
        outline: 0;
      }
    }
  }

  .questionAndAnswerWrapper {
    margin-bottom: 30px;

    h2 {
      margin-bottom: 15px;
      font-family: $font-primary-bold;
    }

    p {
      font-size: 16px;
      font-family: $font-primary-bold;

      span {
        font-weight: 100;
        display: block;
        padding-top: 5px;
        font-family: $font-primary-light;
      }
    }
  }

  .datePickerInput {
    @include input;
    background: url('../../resources/images/calendar_icon.svg') no-repeat right $white;
    background-position-x: 95%;
    margin: 15px 0 0 0;

    &:hover {
      border: 1px solid var(--secondary-color);
    }
  }

  .inputDate {
    @include input;
    background: url('../../resources/images/calendar_icon.svg') no-repeat right $white;
    background-position-x: 95%;
    margin: 15px 0 0 0;
    position: relative;
    -webkit-appearance: none;
    display: flex;
    align-items: center;

    &:hover {
      border: 1px solid var(--secondary-color);
    }

    &:disabled {
      background-color: $myChoiceContentBackground;
      color: $myChoicePlaceholderGray;
    }
  }

  /* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
  input[type="month"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }

  .emptyDate::-moz-selection { /* Code for Firefox */
    color: red;
    background: yellow;
  }

  .emptyDate::selection {
    color: red;
    background: yellow;
  }

  .emptyDate {
    color: transparent;
  }

  .emptyDate:before {
    content: attr(placeholder);
    color: black;
  }

  .react-date-picker__wrapper {
    border: 0;
  }

  .react-date-picker__calendar-button {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    outline: none;
  }

  .react-date-picker__calendar {
    width: 100%;
    z-index: 35;
  }

  .react-calendar {
    width: 100%;
  }

  .react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
    padding: 15px 20px;
  }

  .react-calendar__tile--now {
    background: var(--primary-color);
  }

  .react-calendar__tile--active {
    background: var(--secondary-color);
  }


  .datePickerInputActive {
    @include input;
    background: url('../../resources/images/calendar_icon.svg') no-repeat right $white;
    background-position-x: 95%;
    margin: 15px 0 0 0;
    border: 1px solid var(--secondary-color);
  }

  .react-datepicker-wrapper {
    width: 100%;

    .datePickerInput:disabled {
      background-color: $myChoiceContentBackground;
      color: $myChoicePlaceholderGray;
    }
  }

  .react-datepicker-popper {
    z-index: 9;
  }

  .quotesContentWrapper {
    display: grid;
    grid-template-columns: 465px 675px;
    grid-column-gap: 30px;

    @include bp-small {
      grid-template-columns: 1fr;
    }
    @include iPad-portrait {
      grid-template-columns: 1fr;
    }
    @include iPad-landscape {
      grid-template-columns: 1fr;
    }

    .editQuotesWrapper {
      background-color: $white;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 0 0 4px 4px;
      width: 465px;
      max-width: 465px;
      padding: 30px 30px 0 30px;
      margin-bottom: 30px;
      position: relative;
      box-sizing: border-box;
      @include bp-small {
        width: auto;
        box-shadow: none;
        border: none;
        border-radius: 0;
      }

      .editQuotesTitle {
        font-family: $font-primary-bold;
        padding-bottom: 20px;
      }

      .infoTitleRow {
        h5 {
          font-family: $font-primary-bold;
          padding-bottom: 5px;
        }
      }

      .discountInfoRow {
        padding-bottom: 30px;
        position: relative;

        .editDiscountInfoLink {
          font-size: 16px;
          font-family: $font-primary-bold;
          text-decoration: none;
          color: var(--primary-color);
          cursor: pointer;
          position: absolute;
          right: 0;
        }

        p {
          font-family: $font-primary-light;
        }
      }

      .infoAndEditSingeRow {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 30px;

        p {
          font-family: $font-primary-light;
        }

        .editLink {
          font-size: 16px;
          font-family: $font-primary-bold;
          text-decoration: none;
          color: var(--primary-color);
          cursor: pointer;
        }
      }
    }

    .singleQuotesOfferInfo {
      padding: 30px;
      background-color: $white;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      margin-bottom: 30px;
      position: relative;
      box-sizing: border-box;
      @include bp-small {
        width: 90%;
        margin: 30px auto;
      }
      @include iPad-portrait {
        margin: 30px 0;
        width: 465px;
      }
      @include iPad-landscape {
        margin: 30px 0;
        width: 465px;
      }

      .companyLogo {
        width: 160px;
        @include bp-small {
          width: 90px;
        }
      }

      .brokerLogo {
        width: 160px;
        margin: 0 0 20px 0;
        @include bp-small {
          width: 90px;
        }
      }

      .getRateBtnWrapperMobile {
        margin-bottom: 30px;
      }

      .getRateBtnWrapperDesktop {
        @include iPad-portrait {
          margin: 0 auto;
        }
        @include iPad-landscape {
          margin: 0 auto;
        }
      }

      .errorMsg {
        font-size: 16px;
        text-align: center;
        padding-top: 5px;
      }

      .getRateBtn {
        @include buttonSelected;
        height: 45px;
        width: 185px;
        font-size: 16px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        margin: 0;
        @include bp-small {
          width: 100%;
        }
        @include iPad-portrait {
          width: 100%;
          margin-top: 30px;
        }
        @include iPad-landscape {
          width: 100%;
          margin-top: 30px;
        }
      }

      .getRateBtnDisabled {
        @include buttonDeSelected;
        height: 45px;
        width: 185px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        margin: 0;
        font-size: 16px;
        cursor: not-allowed;
        @include bp-small {
          width: 100%;
        }
        @include iPad-portrait {
          width: 100%;
          margin-top: 30px;
        }
        @include iPad-landscape {
          width: 100%;
          margin-top: 30px;
        }
      }

      .offersFirstRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        h3 {
          font-family: $font-primary-bold;
        }

        .bestOfferLabel {
          height: 30px;
          background: rgba(2, 185, 23, 1);
          border-radius: 4px;
          flex-shrink: 0;

          p {
            font-size: 14px;
            color: $white;
            padding: 7px 15px 0 15px;
          }
        }
      }

      .offersSecondRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        @include iPad-landscape {
          flex-wrap: wrap;
        }
        @include iPad-portrait {
          flex-wrap: wrap;
        }

        h1 {
          font-family: $font-primary-black;
          color: $myChoiceBlack;
          @include bp-small {
            font-size: 22px;
          }

          span {
            font-size: 16px;
            @include bp-small {
              font-size: 10px;
            }
          }
        }

        h2 {
          @include bp-small {
            font-size: 18px;
          }
        }
      }

      .quotePhoneRow {
        width: 100%;
        text-align: center;
        padding: 10px 0 20px;
        h3 {
          margin-bottom: 15px;
        }
        .phoneNumber {
          @include buttonSelected;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 45px;
          width: 210px;
          margin: 0 auto;
        }
      }

      .offersThirdRow {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;

        .viewOffersDetails {
          background: url('../../resources/images/arrow-down-small-gray.svg') no-repeat right $white;
          background-size: 11px;
          border: none;
          outline: none;
          width: 115px;
          color: var(--primary-color);
          font-family: $font-primary-light;
          font-size: 16px;
          cursor: pointer;
        }
      }
    }

    .offersFourthRow {
      h3 {
        margin-bottom: 15px;
        font-family: $font-primary-bold;
        color: $myChoiceSemiBlack;
      }

      h5 {
        margin-bottom: 10px;
        font-family: $font-primary-bold;
        color: $myChoiceSemiBlack;

        span {
          font-family: $font-primary-light;
        }
      }

      p {
        font-size: 20px;
        font-family: $font-primary-light;
        margin-bottom: 30px;
        border-bottom: 1px solid #707070;
        padding-bottom: 30px;
      }

      .phoneNumber {
        @include buttonSelected;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        width: 210px;
        margin: 15px 0 30px 0;
      }
    }
  }

  .select-box {
    position: relative;
    display: block;
    width: 100%;
    margin: 0 auto;
    font-family: $font-primary-regular;
    font-size: 18px;


    @media (min-width: 768px) {
      width: 70%;
    }


    &__current {
      position: relative;
      box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
      cursor: pointer;
      outline: none;

      &:focus {
        & + .select-box__list {

          animation-name: none;

          .select-box__option {
            cursor: pointer;
          }
        }
      }
    }

    &__icon {
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
      width: 20px;
      transition: 0.2s ease;
    }

    &__value {
      display: flex;
    }

    &__input {
      display: none;

      &:checked + .select-box__input-text {
        display: block;
      }
    }


    &__input-text {
      display: none;
      width: 100%;
      margin: 0;
      padding: 20px 0 20px 30px;
      border-radius: 4px 4px 0 0;
      color: $white;
      font-size: 18px;
      background-color: var(--primary-color);
    }

    &__list {
      position: absolute;
      width: 100%;
      padding: 0;
      list-style: none;
      opacity: 1;
      z-index: 1;
      margin: 0;
      background: $white;
      animation-name: HideList;
      animation-duration: 0.5s;
      animation-delay: 0.5s;
      animation-fill-mode: forwards;
      animation-timing-function: step-start;
      box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #F2F2F2;

        &:hover {
          background-color: #F2F2F2;
        }

        .mobileDeleteBtn {
          width: 60px;
          margin-right: 30px;
          font-size: 18px;
          font-family: $font-primary-light;
          color: #212121;
          background: transparent;
          border: none;
          padding: 0;
        }
      }

      .addVehicleMobile {
        padding: 15px 0 15px 30px;
        font-size: 18px;
        font-family: $font-primary-regular;
      }
    }

    &__option {
      display: block;
      padding: 15px 0 15px 20px;
      margin: 0 10px;
      color: #212121;
      outline: none;
      font-family: $font-primary-regular;
      font-size: 18px;
      flex-grow: 1;

      &:hover,
      &:focus {
        color: #546c84;
        background-color: #fbfbfb;
      }
    }
  }

  @keyframes HideList {
    from {
      transform: scaleY(1);
    }
    to {
      transform: scaleY(1);
    }
  }

  .inputDateSelects {
    display: flex;
    align-items: center;
    justify-content: space-between;

    select {
      @include select;
      background-position-x: 90%;
      &.formSelect{
        background-position-x: 95%;
      }
      border-radius: 4px;
      height: 60px;
      border: 1px solid #A7A7A7;
      outline: none;
      color: #212121;
      font-size: 18px;
      padding-left: 20px;
      display: block;
      box-sizing: border-box;
      margin: 15px 0 0 0;
    }

    .daySelect, .yearSelect {
      flex-grow: 1;
    }

    .daySelect {
      margin-right: 14px;
    }

    .yearSelect {
      margin-left: 7px;
    }

    .monthSelect {
      flex-grow: 2;
      margin: 15px 7px 0 0;
    }
  }
}


.ReactModal__Content {
  .formButtonsWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  h3 {
    font-family: $font-primary-bold;
    text-align: center;
    margin-bottom: 30px;
    padding: 0 10%;
  }

  .selectedBtn {
    @include buttonSelected;
    width: 195px;
    margin: 0 8px 0 10px;
    @include bp-small {
      width: 135px;
    }
  }

  .deSelectedBtn {
    @include buttonDeSelected;
    width: 195px;
    margin: 0 8px 0 10px;
    @include bp-small {
      width: 135px;
    }
  }
}

.ReactModal__Overlay {
  z-index: 45;
  background-color: rgba(68, 68, 68, 0.7) !important;
}
