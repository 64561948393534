.contentWrapper{
  background-color: #F2F2F2;
  margin-top: 50px;
  width: 100%;
  overflow-x: hidden;
  min-height: calc(100vh - 50px);
  @include bp-small {
    min-height: auto;
    margin-top: 0;
  }
}

.contentWrapperVehicle{
  margin-top: 140px;
}
.contentWrapperVehicleMobile{
  margin-top: 125px;
  @include bp-small {
    margin-top: 0;
  }
}

.pageWrapper {
  background-color: #F2F2F2;
  display: flex;
  padding: 115px 0 75px 0;
  align-items: center;
  flex-direction: column;
}
.content-loader{
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 50;
  top: 0;
  background-color: rgba(0,0,0,0.5);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .loader-wrapper{
    text-align: center;
    h4{
      padding: 0 20% 0 20%;
    }
    img{
      margin-top: 1em;
    }
    .icon-loader-primary {
      margin-top: 1em;
    }
  }
}
