.myProfileWrapper{
  width: 1170px;
  max-width: 1170px;
  margin: 60px auto 0 auto;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 60px 140px;
  box-sizing: border-box;
   @include bp-small {
     margin: 0 auto;
     width: auto;
     padding: 20px;
   }

  .loading {
    display: flex;
    flex-direction: row;
    padding-left: 44%;

    div {
      height: 7px;
      width: 7px;
      margin-right: 3%;
      background-color: $white;
      border-radius: 50%;
      animation-name: blink;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;

      &:nth-child(2) {
        animation-delay: .1s;
      }

      &:nth-child(3) {
        animation-delay: .2s;
      }
    }

    @keyframes blink {
      0% {
        opacity: .2;
      }
      20% {
        opacity: 1;
      }
      100% {
        opacity: .2;
      }
    }
  }

  .myProfileInfo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 140px;
    width: 100%;
    @include bp-small {
      grid-template-columns: 1fr;
    }
    h5 {
      font-family: $font-primary-bold;
      margin-bottom: 10px;
    }
    p {
      font-size: 12px;
      margin-bottom: 30px;
    }
    a {
      font-size: 12px;
      color: #104AE9;
      text-decoration: none;
    }
  }

  .passChangeLink{
    display: block;
    text-align: center;
    margin-top: 30px;
    font-size: 12px;
    color: #104AE9;
    text-decoration: none;
  }

  .myProfileFiledLeftSide {
    display: flex;
    justify-content: space-between;
  }

  .myProfileEditRightSide {
    .selectedGenderBtn,  .deSelectedGenderBtn {
      width: 48%;
      margin: 15px 5px 30px 5px;
    }
  }

  .btnWrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    .profileActionBtn {
      @include buttonSelected;
      width: 195px;
      height: 45px;
      margin: 0;
      /* for a tag */
      text-align: center;
      line-height: 45px;
      text-decoration: none;
      &:hover {
        opacity: 0.7;
      }
    }
  }

  .formLabelAndTooltipWrapper, .formButtonsWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    img {
      cursor: pointer;
    }
    h4 {
      font-family: $font-primary-bold;
      @include bp-small {
        font-size: 16px;
      }
    }

    .deSelectedBtn {
      @include buttonDeSelected;
    }

    .genderBtn {
      width: 31%;
    }

    .tooltipIcon {
      background: url('../../resources/images/qm-not-filled.svg') no-repeat $white;
      height: 18px;
      width: 18px;
      &:hover {
        background: url('../../resources/images/qm-filled.svg') no-repeat $white;
        cursor: pointer;
      }
    }

    .tooltipWrapper {
      background-color: $white !important;
      opacity: 1 !important;
      width: 20%;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      @include bp-small {
        width: 70%;
        left: 35px !important;
      }
    }
    .tooltipText {
      font-size: 14px;
      background: $white !important;
      color: $myChoiceSemiBlack;
      font-family: $font-primary-light;
      margin-bottom: 0;
    }
  }

  .formInput {
    @include input;
    &:hover{
      border: 1px solid var(--secondary-color);
    }
  }
  .formInputActive {
    @include input;
    border: 1px solid var(--secondary-color);
  }
  .formSelect {
    @include input;
    @include select;
    &:hover{
      border: 1px solid var(--secondary-color);
    }
  }
  /* added for my profile view */
  .formInputReadOnly{
    @include input;
    border: none;
    &:hover{
      border: none;
    }
    &:focus{
      border: none;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .datePickerInput {
    @include input;
    background: url('../../resources/images/calendar_icon.svg') no-repeat right $white;
    background-position-x: 95%;
    margin: 15px 0 0 0;
    &:hover{
      border: 1px solid var(--secondary-color);
    }
  }
  // date inputs

  .inputDate {
    @include input;
    background: url('../../resources/images/calendar_icon.svg') no-repeat right $white;
    background-position-x: 95%;
    margin: 15px 0 0 0;
    position: relative;
    -webkit-appearance: none;
    display: flex;
    align-items: center;
    &:hover{
      border: 1px solid var(--secondary-color);
    }
    &:disabled {
      background-color: $myChoiceContentBackground;
      color: $myChoicePlaceholderGray;
    }
  }

  /* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
  input[type="month"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }

  .emptyDate::-moz-selection { /* Code for Firefox */
    color: red;
    background: yellow;
  }

  .emptyDate::selection {
    color: red;
    background: yellow;
  }
  .emptyDate{
    color: transparent;
  }
  .emptyDate:before{
    content: attr(placeholder);
    color: black;
  }

  .inputDateSelects {

    display: flex;
    align-items: center;
    justify-content: space-between;

    select {
      @include select;
      background-position-x: 90%;
      border-radius: 4px;
      height: 60px;
      border: 1px solid #A7A7A7;
      outline: none;
      color: #212121;
      font-size: 18px;
      padding-left: 20px;
      display: block;
      box-sizing: border-box;
      margin: 15px 0 30px 0;
    }
    .daySelect, .yearSelect {
      flex-grow: 1;
    }
    .daySelect {
      margin-right: 14px;
    }
    .yearSelect {
      margin-left: 7px;
    }
    .monthSelect {
      flex-grow: 2;
      margin: 15px 7px 30px 0;
    }
  }

}
