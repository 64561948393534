@font-face {
  font-family: 'Latoblack';
  src: url('../../resources/fonts/lato-black-webfont.woff2') format('woff2'),
  url('../../resources/fonts/lato-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Latobold';
  src: url('../../resources/fonts/lato-bold-webfont.woff2') format('woff2'),
  url('../../resources/fonts/lato-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Latolight';
  src: url('../../resources/fonts/lato-light-webfont.woff2') format('woff2'),
  url('../../resources/fonts/lato-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Latoregular';
  src: url('../../resources/fonts/lato-regular-webfont.woff2') format('woff2'),
  url('../../resources/fonts/lato-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

$font-primary-regular: 'Latoregular', sans-serif;
$font-primary-bold: 'Latobold', sans-serif;
$font-primary-black: 'Latoblack', sans-serif;
$font-primary-light: 'Latolight', sans-serif;
