//Inputs
@mixin input {
  border-radius: 4px;
  height: 60px;
  border: 1px solid $myChoicePlaceholderGray;
  outline: none;
  color: $myChoiceBlack;
  font-size: 18px;
  padding-left: 20px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin: 15px 0 30px 0;
  font-family: $font-primary-regular;
  &:focus {
    border: 1px solid var(--secondary-color);
  }
  &:hover {
    border: 1px solid var(--secondary-color);
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $myChoicePlaceholderGray;
    font-family: $font-primary-regular;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $myChoicePlaceholderGray;
    font-family: $font-primary-regular;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $myChoicePlaceholderGray;
    font-family: $font-primary-regular;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $myChoicePlaceholderGray;
    font-family: $font-primary-regular;
  }
}

@mixin select {
  background: url('../../resources/images/arrow-down-small-gray.svg') no-repeat right $white;
  -webkit-appearance: none;
  background-position-x: 95%;
  background-size: 11px;
  color: black;
  font-family: $font-primary-regular;
  &:required:invalid {
    color: $myChoicePlaceholderGray;
  }
  &:focus {
    border: 1px solid var(--secondary-color);
  }
  &:hover {
    border: 1px solid var(--secondary-color);
  }
  &:disabled {
    background-color: $myChoiceContentBackground;
    color: $myChoicePlaceholderGray;
  }
}

@mixin buttonSelected {
  height: 60px;
  width: 48%;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: $white;
  border-radius: 4px;
  margin: 15px 0 30px 0;
  font-size: 18px;
  outline: none;
  cursor: pointer;
  font-family: $font-primary-bold;
}

@mixin buttonDeSelected {
  height: 60px;
  width: 48%;
  background-color: $white;
  border: 1px solid $myChoiceSemiBlack;
  color: $myChoiceSemiBlack;
  border-radius: 4px;
  margin: 15px 0 30px 0;
  font-size: 18px;
  outline: none;
  cursor: pointer;
  font-family: $font-primary-bold;
}


// Breakpoints
@mixin bp-large {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

@mixin bp-medium {
  @media only screen and (max-width: 991px) {
    @content;
  }
}

@mixin bp-small {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin bp-not-mobile {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin iPad-landscape {
  @media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1024px)
  and (orientation : landscape) {
    @content;
  }
}

@mixin iPad-portrait {
  @media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1024px)
  and (orientation : portrait) {
    @content;
  }
}
