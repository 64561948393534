//Colors
$white: #ffffff;
$myChoiceOrange: #F9733E;
$myChoiceInactiveOrange: #FCAD8E;
$myChoiceContentBackground: #F2F2F2;
$myChoiceTabBackground: #E5E5E5;
$myChoiceBlack: #212121;
$myChoiceSemiBlack: #444444;
$myChoiceDarkBlue: #2A363D;
$myChoiceGray: #777777;
$myChoicePlaceholderGray: #A7A7A7;
$myChoiceSemiGray: #C8C8C8;
$myChoiceNavbarColor: #666666;
$myChoiceGreen: #4ABDAC;
$myChoiceRed: #f50011;
$myChoiceTextGrey: #959595;
$myChoiceQuoteGreen: #02B917;
$color-blue-light: #176DE3;

$color-background-header: $white;

$color-text-tertiary: $myChoiceBlack;

$border-input-default: 1px solid $myChoiceBlack;

:root {
  --primary-color: #{$myChoiceOrange};
  --primary-hover: rgba(0,0,0,0.2);
  --secondary-color: #{$myChoiceGreen};
}
